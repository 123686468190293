import { useApi } from '@tenant/composables'

export type Employee = {
    id: string
    firstname: string
    lastname: string
}

export type EmployeesState = {
    employees: Employee[]
}

export default {
    state: (): EmployeesState => ({
        employees: [],
    }),

    mutations: {
        setEmployees(state: EmployeesState, employees: Employee[]) {
            state.employees = employees
        },
    },

    getters: {
        employees(state: EmployeesState) {
            return state.employees
        },
    },

    actions: {
        fetchEmployees({ commit }: { commit: (...args: any[]) => void }) {
            const { execute } = useApi('/api/employees', 'GET')

            execute().then((response) => {
                const items = response.data.map((employee: Employee) => ({
                    ...employee,
                    fullName: `${employee.firstname} ${employee.lastname}`,
                }))

                commit('setEmployees', items)
            })
        },
    },
}
